export const getNormalizedLocale = (locale: string) => {
  switch (locale.replace('-', '_')) {
    case 'de_CH':
    case 'de_AT':
    case 'de_LI':
    case 'de_LU':
      return 'de_DE';
    case 'fr_CH':
    case 'fr_MC':
    case 'fr_LU':
    case 'fr_BE':
    case 'fr_CA':
      return 'fr_FR';
    case 'es_AD':
      return 'es_ES';
    case 'it_CH':
      return 'it_IT';
    case 'nl_BE':
      return 'nl_NL';
    case 'en_BG':
    case 'en_CH':
    case 'en_CY':
    case 'en_EE':
    case 'en_FI':
    case 'en_GB':
    case 'en_GI':
    case 'en_GR':
    case 'en_HR':
    case 'en_IE':
    case 'en_IS':
    case 'en_LT':
    case 'en_LV':
    case 'en_MT':
    case 'en_RO':
    case 'en_SI':
    case 'en_SK':
    case 'hu_HU':
    case 'sk_SK':
      return 'en_GB';
    case 'en_CA':
      return 'en_US';
    default:
      return locale.replace('-', '_');
  }
};
