import defaultLocale from './default.json';
import csCZ from './cs-CZ.json';
import daDK from './da-DK.json';
import deAT from './de-AT.json';
import deCH from './de-CH.json';
import deDE from './de-DE.json';
import deLU from './de-LU.json';
import enCA from './en-CA.json';
import enCH from './en-CH.json';
import enEE from './en-EE.json';
import enFI from './en-FI.json';
import enGB from './en-GB.json';
import enHR from './en-HR.json';
import enIE from './en-IE.json';
import enLT from './en-LT.json';
import enLV from './en-LV.json';
import enSI from './en-SI.json';
import enSK from './en-SK.json';
import enUS from './en-US.json';
import esES from './es-ES.json';
import etEE from './et-EE.json';
import fiFI from './fi-FI.json';
import frBE from './fr-BE.json';
import frCA from './fr-CA.json';
import frCH from './fr-CH.json';
import frFR from './fr-FR.json';
import frLU from './fr-LU.json';
import huHU from './hu-HU.json';
import itCH from './it-CH.json';
import itIT from './it-IT.json';
import ltLT from './lt-LT.json';
import lvLV from './lv-LV.json';
import nlBE from './nl-BE.json';
import nlNL from './nl-NL.json';
import noNO from './no-NO.json';
import plPL from './pl-PL.json';
import ptPT from './pt-PT.json';
import ruRU from './ru-RU.json';
import skSK from './sk-SK.json';
import svSE from './sv-SE.json';

const translations = {
  'default': defaultLocale,
  'cs-CZ': csCZ,
  'da-DK': daDK,
  'de-AT': deAT,
  'de-CH': deCH,
  'de-DE': deDE,
  'de-LU': deLU,
  'en-CA': enCA,
  'en-CH': enCH,
  'en-EE': enEE,
  'en-FI': enFI,
  'en-GB': enGB,
  'en-HR': enHR,
  'en-IE': enIE,
  'en-LT': enLT,
  'en-LV': enLV,
  'en-SI': enSI,
  'en-SK': enSK,
  'en-US': enUS,
  'es-ES': esES,
  'et-EE': etEE,
  'fi-FI': fiFI,
  'fr-BE': frBE,
  'fr-CA': frCA,
  'fr-CH': frCH,
  'fr-FR': frFR,
  'fr-LU': frLU,
  'hu-HU': huHU,
  'it-CH': itCH,
  'it-IT': itIT,
  'lt-LT': ltLT,
  'lv-LV': lvLV,
  'nl-BE': nlBE,
  'nl-NL': nlNL,
  'no-NO': noNO,
  'pl-PL': plPL,
  'pt-PT': ptPT,
  'ru-RU': ruRU,
  'sk-SK': skSK,
  'sv-SE': svSE,
} as { [key: string]: any };

export default translations;
