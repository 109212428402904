import { FC, useEffect, useState } from 'react';
import {
  APIProvider,
  Map as GoogleMap,
  Marker,
  useMapsLibrary,
} from '@vis.gl/react-google-maps';
import style from './map.module.scss';

type MapProps = {
  address?: string;
};

type GeocodeData = {
  lat: number;
  lng: number;
};

const GMap: FC<MapProps> = ({ address }) => {
  const geocodingLibrary = useMapsLibrary('geocoding');
  const [geocodingService, setGeocodingService] =
    useState<google.maps.Geocoder | null>(null);
  const [position, setPosition] = useState<GeocodeData | null>(null);

  useEffect(() => {
    if (!geocodingLibrary) return;
    setGeocodingService(new geocodingLibrary.Geocoder());
  }, [geocodingLibrary]);

  useEffect(() => {
    geocodingService?.geocode({ address }, (results, status) => {
      if (status === 'OK') {
        if (results?.[0]) {
          setPosition({
            lat: results[0]?.geometry.location.lat(),
            lng: results[0]?.geometry.location.lng(),
          });
        }
      } else {
        console.error(
          'There was an error in fetching the geocoding data: ',
          status,
        );
      }
    });
  }, [geocodingService]);

  if (!position) return null;

  return (
    <div className={style.container}>
      <GoogleMap
        defaultCenter={position}
        defaultZoom={15}
        disableDefaultUI={true}
      >
        <Marker position={position} icon="/marker.svg" />
      </GoogleMap>
    </div>
  );
};

export const Map: FC<MapProps> = ({ address }) => {
  if (!address) return null;

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}>
      <GMap address={address} />
    </APIProvider>
  );
};
