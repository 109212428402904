/* eslint-disable @typescript-eslint/naming-convention */

export enum PcsLocale {
  cs_CZ = 'cs-CZ',
  da_DK = 'da-DK',
  de_AT = 'de-AT',
  de_CH = 'de-CH',
  de_DE = 'de-DE',
  de_LI = 'de-LI',
  de_LU = 'de-LU',
  en_CA = 'en-CA',
  en_CH = 'en-CH',
  en_EE = 'en-EE',
  en_FI = 'en-FI',
  en_GB = 'en-GB',
  en_HR = 'en-HR',
  en_IE = 'en-IE',
  en_LT = 'en-LT',
  en_LV = 'en-LV',
  en_SI = 'en-SI',
  en_SK = 'en-SK',
  en_US = 'en-US',
  es_ES = 'es-ES',
  et_EE = 'et-EE',
  fi_FI = 'fi-FI',
  fr_BE = 'fr-BE',
  fr_CA = 'fr-CA',
  fr_CH = 'fr-CH',
  fr_FR = 'fr-FR',
  fr_LU = 'fr-LU',
  hr_HR = 'hr-HR',
  hu_HU = 'hu-HU',
  it_CH = 'it-CH',
  it_IT = 'it-IT',
  lt_LT = 'lt-LT',
  lv_LV = 'lv-LV',
  nl_BE = 'nl-BE',
  nl_NL = 'nl-NL',
  no_NO = 'no-NO',
  pl_PL = 'pl-PL',
  pt_PT = 'pt-PT',
  ru_RU = 'ru-RU',
  sk_SK = 'sk-SK',
  sl_SI = 'sl-SI',
  sv_SE = 'sv-SE',
}
