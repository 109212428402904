import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { PriceAcceptPage } from './priceAccept/PriceAcceptPage';
import { SubscriptionContextProvider } from '../../components/context/subscription/SubscriptionContextProvider';

export const PriceUpdatePage: React.FunctionComponent = () => {
  return (
    <SubscriptionContextProvider>
      <Routes>
        <Route element={<PriceAcceptPage />} path={'*'} />
      </Routes>
    </SubscriptionContextProvider>
  );
};
