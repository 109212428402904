import { FC } from 'react';
import {
  PButton,
  PHeading,
  PText,
} from '@porsche-design-system/components-react';
import { FrostedHeading } from 'components/layout/frosted/FrostedHeading';
import { FrostedItem } from 'components/layout/frosted/FrostedItem';
import { FrostedLayout } from 'components/layout/frosted/FrostedLayout';
import { Step, Stepper } from 'components/stepper/Stepper';
import style from 'pages/confirmation/failure/failurePage.module.scss';
import background from 'media/backgroundFailure.jpg';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { messages } from './failurePage.messages';
import { FormattedMessage } from 'react-intl';

export const FailurePage: FC = () => {
  const [searchParams] = useSearchParams();
  const hasFailedOnSanctionCheck = searchParams.get('sc');
  const title = hasFailedOnSanctionCheck
    ? messages.titleSanctionCheck
    : messages.title;
  const subtitle = hasFailedOnSanctionCheck && messages.subtitleSanctionCheck;
  const { vin, marketplace, locale } = useParams();

  return (
    <div data-testid={'failurePage'}>
      <Stepper currentStep={Step.Confirmation} />
      <FrostedLayout background={background}>
        <FrostedHeading data-testid="failure-title">
          <FormattedMessage {...title} />
        </FrostedHeading>
        {subtitle ? (
          <FrostedItem data-testid="failure-subtitle">
            <PHeading size={'x-large'}>
              <FormattedMessage {...subtitle} />
            </PHeading>
          </FrostedItem>
        ) : null}
        <FrostedItem>
          <div className={style.container}>
            <PText>
              <FormattedMessage
                {...{
                  ...messages.help1,
                  values: {
                    customerSupport: (
                      <a
                        href={`${process.env.REACT_APP_MY_PORSCHE_URL}/core/${marketplace}/${locale}/contact`}
                      >
                        <FormattedMessage
                          {...messages.customerSupportLinkLabel}
                        />
                      </a>
                    ),
                  },
                }}
              />
            </PText>
          </div>
        </FrostedItem>
        <Link
          to={`${process.env.REACT_APP_MY_PORSCHE_URL}/core/${marketplace}/${locale}/vehicles/${vin}`}
        >
          <PButton className={style.button}>
            <FormattedMessage {...messages.action} />
          </PButton>
        </Link>
      </FrostedLayout>
    </div>
  );
};
