import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from './useParams';
import { fetchApi } from 'utils/fetchApi';
import { useAuthentication } from './useAuthentication/useAuthentication';
import { featureFlagsContext } from 'components/context/featureFlags/FeatureFlagsContext';

type LegalDoc = {
  type: string;
  version: string;
  url: string;
  pdfUrl: string;
};

type LegalDocs = {
  countryLegalDocs: LegalDoc[];
};

export const useTermsAndConditions = (subscriptionId?: string | null) => {
  const { marketplace, locale } = useParams();
  const [data, setData] = useState<LegalDocs | null>(null);
  const [upgradeEndDate, setUpgradeEndDate] = useState<string | null>(null);
  const { token } = useAuthentication();
  const [isTermsLoading, setIsTermsLoading] = useState(false);
  const [isUpgradeLoading, setIsUpgradeLoading] = useState(false);
  const contextFeatureFlags = useContext(featureFlagsContext);
  const isCheckForNewTermsEnabled =
    contextFeatureFlags.featureFlags.termsAndConditionsUpdate?.enabled;

  const checkForNewTermsAndConditions = useCallback(async () => {
    if (!isCheckForNewTermsEnabled) {
      return;
    }

    setIsTermsLoading(true);
    try {
      const response = await fetchApi(
        `${process.env.REACT_APP_BASE_API_URL}/my/subscriptions/${subscriptionId}/terms-and-conditions`,
        token,
        {
          method: 'GET',
        },
      );

      if (!response.ok) {
        throw new Error('Error fetching terms and conditions');
      }

      const data = await response.json();

      if (!data.acceptanceRequired) {
        return;
      }

      setUpgradeEndDate(data.acceptanceRequired.endDate);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      setIsTermsLoading(false);
    }
  }, [subscriptionId, token, isCheckForNewTermsEnabled]);

  const upgradeTermsAndConditions = useCallback(async () => {
    setIsUpgradeLoading(true);
    try {
      const response = await fetchApi(
        `${process.env.REACT_APP_BASE_API_URL}/my/subscriptions/${subscriptionId}/terms-and-conditions/upgrade`,
        token,
        {
          method: 'POST',
        },
      );

      if (!response.ok) {
        throw new Error('Error updating terms and conditions');
      }
    } catch (error: unknown) {
      console.error(error);
      throw new Error('Error updating terms and conditions');
    } finally {
      setIsUpgradeLoading(false);
    }
  }, [subscriptionId, token]);

  const fetchLegalDocs = useCallback(
    (marketplace: string) =>
      fetch(
        `${process.env.REACT_APP_TAC_API_URL}?country=${marketplace}&locale=${locale}`,
      )
        .then((response) => {
          return response.json();
        })
        .catch((err) => {
          console.error(err);
        }),
    [],
  );

  useEffect(() => {
    if (marketplace && !data) {
      fetchLegalDocs(marketplace).then((fetchedData) => setData(fetchedData));
    }
  }, [marketplace, data, fetchLegalDocs]);

  const { tacDoc, dpgDoc, touDoc } = useMemo(() => {
    if (!data || !data.countryLegalDocs) {
      return { tacDoc: null, dpgDoc: null, touDoc: null };
    }

    const tacDoc =
      data.countryLegalDocs.find((doc) => doc.type === 'TAC')?.pdfUrl || null;
    const dpgDoc =
      data.countryLegalDocs.find((doc) => doc.type === 'DPG')?.pdfUrl || null;
    const touDoc =
      data.countryLegalDocs.find((doc) => doc.type === 'TOU')?.pdfUrl || null;

    return { tacDoc, dpgDoc, touDoc };
  }, [data]);

  return {
    tacDoc,
    dpgDoc,
    touDoc,
    isTermsLoading,
    checkForNewTermsAndConditions,
    upgradeTermsAndConditions,
    upgradeEndDate,
    isUpgradeLoading,
    isCheckForNewTermsEnabled,
  };
};

export default useTermsAndConditions;
