import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  PButton,
  PButtonPure,
  PFlyout,
  PHeading,
  PInlineNotification,
  PText,
} from '@porsche-design-system/components-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages as flyoutMessages } from '../flyout.messages';
import style from './terminate.module.scss';
import { CustomerService } from '../customerService/CustomerService';
import { observer } from 'mobx-react-lite';
import { messages } from './terminate.messages';
import { subscriptionContext } from '../../../context/subscription/SubscriptionContext';
import { PlanVariant } from '../../../context/plan/planContext.types';

export const Terminate: React.FunctionComponent = observer((_props) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [locked, setLocked] = useState(false);
  const [success, setSuccess] = useState(false);
  const intl = useIntl();
  const context = useContext(subscriptionContext);

  /**
   * Opens the termination flyout
   */
  const onOpen = useCallback(() => {
    setError(false);
    setOpen(true);
  }, []);

  /**
   * Closes the termination flyout
   */
  const onClose = useCallback(() => {
    if (locked) {
      return;
    }
    setOpen(false);
  }, [locked]);

  /**
   * Terminates the contract
   */
  const onTerminate = useCallback(async () => {
    if (!context.subscription) {
      return;
    }
    setLocked(true);
    const result = await context.terminate();

    if (result) {
      setError(false);
      setLocked(false);
      setSuccess(true);
      return;
    }

    setError(true);
    setLocked(false);
  }, [context]);

  /**
   * Display either a success message or an action button <br/>
   * Except V1 because it's already pre-terminated
   */
  const content = useMemo(() => {
    if (success) {
      return (
        <PText>
          <FormattedMessage {...messages.success} />
        </PText>
      );
    }

    if (
      context.subscription?.currentOrLastPhase.plan.variant !==
        PlanVariant.V1_PREMIUM_INCLUSIVE &&
      context.subscription?.isTerminated
    ) {
      return (
        <PText>
          <FormattedMessage {...messages.success} />
        </PText>
      );
    }

    return (
      <>
        <PText>
          <FormattedMessage {...messages.confirm} />
        </PText>
        <div className={style.actions}>
          <PButton loading={locked} onClick={onTerminate}>
            <FormattedMessage {...messages.action} />
          </PButton>
          <PButton variant={'secondary'} onClick={onClose} disabled={locked}>
            <FormattedMessage {...messages.cancel} />
          </PButton>
        </div>
      </>
    );
  }, [
    success,
    context.subscription?.currentOrLastPhase.plan.variant,
    context.subscription?.isTerminated,
    locked,
    onTerminate,
    onClose,
  ]);

  /**
   * Conditionally display an error message
   */
  const errorComponent = useMemo(() => {
    if (!error) {
      return null;
    }
    return (
      <PInlineNotification
        heading={intl.formatMessage(messages.errorTitle)}
        description={intl.formatMessage(messages.errorText)}
        state={'error'}
        className={style.error}
        dismissButton={false}
      />
    );
  }, [error, intl]);

  /**
   * Disallows termination if already terminated <br/>
   * Except V1 because it's already pre-terminated
   */
  const disabled = useMemo(() => {
    if (!context.subscription || context.subscription.isExpired || success) {
      return true;
    }
    if (
      context.subscription?.currentOrLastPhase.plan.variant ===
      PlanVariant.V1_PREMIUM_INCLUSIVE
    ) {
      return false;
    }
    return context.subscription.isTerminated;
  }, [context.subscription, success]);

  return (
    <>
      <PButtonPure icon={'disable'} onClick={onOpen} disabled={disabled}>
        <FormattedMessage {...messages.terminate} />
      </PButtonPure>
      <PFlyout open={open} onDismiss={onClose}>
        <span slot={'header'}>
          <PHeading>
            <FormattedMessage {...flyoutMessages.title} />
          </PHeading>
        </span>

        {errorComponent}

        <div className={style.table}>
          <PHeading size={'large'}>
            <FormattedMessage {...messages.terminate} />
          </PHeading>
          {content}
        </div>

        <CustomerService />
      </PFlyout>
    </>
  );
});
