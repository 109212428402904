import { useParams } from 'react-router-dom';

import style from './footer.module.scss';

const currentYear = new Date().getFullYear();
const copyrightText = `© ${currentYear}`;

const createLinkWithText = (text: string, linkUrl: string) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={linkUrl}
      className={style.anchor}
      key={linkUrl}
    >
      {text}
    </a>
  );
};

const createLinksFromArray = (links: { text: string; linkUrl: string }[]) => {
  return links.map(({ text, linkUrl }) => createLinkWithText(text, linkUrl));
};

const deDETopLinks = [
  {
    text: 'Impressum und Rechtliche Hinweise.',
    linkUrl: 'https://connect-store2.porsche.com/de/de/t/about',
  },
  {
    text: 'Wirtschaft und Menschenrechte.',
    linkUrl: 'https://connect-store.porsche.com/de/de/business_human_rights',
  },
  {
    text: 'AGB.',
    linkUrl: 'https://connect-store2.porsche.com/de/de/t/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl: 'https://connect-store.porsche.com/de/de/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: `${window.location.origin}/licenses`,
  },
  {
    text: 'Hinweise zum Datenschutz.',
    linkUrl: 'https://connect-store2.porsche.com/de/de/t/privacy',
  },
  {
    text: 'Ergänzende Datenschutzinformationen.',
    linkUrl:
      'https://connect-store.porsche.com/de/de/additional-privacy-information',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/de/de/digital_services_act?model=',
  },
  {
    text: 'Verbrauchsinformationen.',
    linkUrl: 'https://www.porsche.com/germany/verbrauchsinformationen/',
  },
];

const enGBTopLinks = [
  {
    text: 'Legal Notice.',
    linkUrl: 'https://connect-store2.porsche.com/gb/en/t/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/gb/en/business_human_rights',
  },
  {
    text: 'Terms and Conditions.',
    linkUrl: 'https://connect-store2.porsche.com/gb/en/t/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl: 'https://www.porsche.com/uk/cookies/',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: `${window.location.origin}/licenses`,
  },
  {
    text: 'Data Privacy.',
    linkUrl: 'https://connect-store2.porsche.com/gb/en/t/privacy',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/gb/en/additional-privacy-information',
  },
];

const enCATopLinks = [
  {
    text: 'Legal Notice.',
    linkUrl: 'https://connect-store2.porsche.com/ca/en/t/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/ca/en/business_human_rights',
  },
  {
    text: 'Terms and Conditions.',
    linkUrl: 'https://connect-store2.porsche.com/ca/en/t/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl: 'https://connect-store2.porsche.com/ca/en/t/privacy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: `${window.location.origin}/licenses`,
  },
  {
    text: 'Data Privacy.',
    linkUrl: 'https://connect-store2.porsche.com/ca/en/t/privacy',
  },
];

const deATTopLinks = [
  {
    text: 'Impressum und Rechtliche Hinweise.',
    linkUrl: 'https://connect-store2.porsche.com/at/de/t/about',
  },
  {
    text: 'Wirtschaft und Menschenrechte.',
    linkUrl: 'https://connect-store.porsche.com/at/de/business_human_rights',
  },
  {
    text: 'AGB.',
    linkUrl: 'https://connect-store2.porsche.com/at/de/t/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl: 'https://connect-store.porsche.com/at/de/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: `${window.location.origin}/licenses`,
  },
  {
    text: 'Hinweise zum Datenschutz.',
    linkUrl: 'https://connect-store2.porsche.com/at/de/t/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/at/de/digital_services_act?model=',
  },
  {
    text: 'Ergänzende Datenschutzinformationen.',
    linkUrl:
      'https://connect-store.porsche.com/at/de/additional-privacy-information',
  },
];

const nlNLTopLinks = [
  {
    text: 'Colofon en juridische kennisgevingen.',
    linkUrl: 'https://connect-store2.porsche.com/nl/nl/t/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/nl/nl/business_human_rights',
  },
  {
    text: 'Algemene voorwaarden.',
    linkUrl: 'https://connect-store2.porsche.com/nl/nl/t/termsandconditions',
  },
  {
    text: 'Cookiebeleid.',
    linkUrl: 'https://connect-store.porsche.com/nl/nl/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: `${window.location.origin}/licenses`,
  },
  {
    text: 'Verklaring gegevensbescherming.',
    linkUrl: 'https://connect-store2.porsche.com/nl/nl/t/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/nl/nl/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/nl/nl/additional-privacy-information',
  },
];

const nlBETopLinks = [
  {
    text: 'Colofon en juridische kennisgevingen.',
    linkUrl: 'https://connect-store2.porsche.com/be/nl/t/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/be/nl/business_human_rights',
  },
  {
    text: 'Algemene voorwaarden.',
    linkUrl: 'https://connect-store2.porsche.com/be/nl/t/termsandconditions',
  },
  {
    text: 'Cookiebeleid.',
    linkUrl: 'https://connect-store.porsche.com/be/nl/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: `${window.location.origin}/licenses`,
  },
  {
    text: 'Verklaring gegevensbescherming.',
    linkUrl: 'https://connect-store2.porsche.com/be/nl/t/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/be/nl/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/be/nl/additional-privacy-information',
  },
];

const csCZTopLinks = [
  {
    text: 'Impressum a právní upozornění.',
    linkUrl: 'https://connect-store2.porsche.com/cz/cs/t/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/cz/cs/business_human_rights',
  },
  {
    text: 'VOP.',
    linkUrl: 'https://connect-store2.porsche.com/cz/cs/t/termsandconditions',
  },
  {
    text: 'Zásady používání souborů cookie.',
    linkUrl: 'https://connect-store.porsche.com/cz/cs/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: `${window.location.origin}/licenses`,
  },
  {
    text: 'Zásady ochrany osobních údajů.',
    linkUrl: 'https://connect-store2.porsche.com/cz/cs/t/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/cz/cs/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/cz/cs/additional-privacy-information',
  },
];

const deCHTopLinks = [
  {
    text: 'Impressum und Rechtliche Hinweise.',
    linkUrl: 'https://connect-store2.porsche.com/ch/de/t/about',
  },
  {
    text: 'Wirtschaft und Menschenrechte.',
    linkUrl: 'https://connect-store.porsche.com/ch/de/business_human_rights',
  },
  {
    text: 'AGB.',
    linkUrl: 'https://connect-store2.porsche.com/ch/de/t/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl: 'https://connect-store.porsche.com/ch/de/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: `${window.location.origin}/licenses`,
  },
  {
    text: 'Hinweise zum Datenschutz.',
    linkUrl: 'https://connect-store2.porsche.com/ch/de/t/privacy',
  },
  {
    text: 'Ergänzende Datenschutzinformationen.',
    linkUrl:
      'https://connect-store.porsche.com/ch/de/additional-privacy-information',
  },
];

const daDKTopLinks = [
  {
    text: 'Kolofon og juridiske bemærkninger.',
    linkUrl: 'https://connect-store2.porsche.com/dk/da/t/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/dk/da/business_human_rights',
  },
  {
    text: 'Generelle forretningsbetingelser.',
    linkUrl: 'https://connect-store2.porsche.com/dk/da/t/termsandconditions',
  },
  {
    text: 'Politik for cookies.',
    linkUrl: 'https://connect-store.porsche.com/dk/da/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: `${window.location.origin}/licenses`,
  },
  {
    text: 'Oplysninger om databeskyttelse.',
    linkUrl: 'https://connect-store2.porsche.com/dk/da/t/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/dk/da/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/dk/da/additional-privacy-information',
  },
];

const etEETopLinks = [
  {
    text: 'Impressum ja õigusalane teave.',
    linkUrl: 'https://connect-store2.porsche.com/ee/et/t/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/ee/et/business_human_rights',
  },
  {
    text: 'Üldised tingimused.',
    linkUrl: 'https://connect-store2.porsche.com/ee/et/t/termsandconditions',
  },
  {
    text: 'Küpsisepoliitika.',
    linkUrl: 'https://connect-store.porsche.com/ee/et/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: `${window.location.origin}/licenses`,
  },
  {
    text: 'Andmekaitsealane teave.',
    linkUrl: 'https://connect-store2.porsche.com/ee/et/t/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/ee/et/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/ee/et/additional-privacy-information',
  },
];

const esESTopLinks = [
  {
    text: 'Aviso legal.',
    linkUrl: 'https://connect-store2.porsche.com/es/es/t/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/es/es/business_human_rights',
  },
  {
    text: 'Términos y condiciones generales.',
    linkUrl: 'https://connect-store2.porsche.com/es/es/t/termsandconditions',
  },
  {
    text: 'Política sobre cookies.',
    linkUrl: 'https://connect-store.porsche.com/es/es/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: `${window.location.origin}/licenses`,
  },
  {
    text: 'Política de privacidad.',
    linkUrl: 'https://connect-store2.porsche.com/es/es/t/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/es/es/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/es/es/additional-privacy-information',
  },
];

const fiFITopLinks = [
  {
    text: 'Julkaisutiedot ja oikeudelliset huomautukset.',
    linkUrl: 'https://connect-store2.porsche.com/fi/fi/t/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/fi/fi/business_human_rights',
  },
  {
    text: 'YSE.',
    linkUrl: 'https://connect-store2.porsche.com/fi/fi/t/termsandconditions',
  },
  {
    text: 'Evästekäytäntö.',
    linkUrl: 'https://connect-store.porsche.com/fi/fi/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: `${window.location.origin}/licenses`,
  },
  {
    text: 'Tietosuojaohjeita.',
    linkUrl: 'https://connect-store2.porsche.com/fi/fi/t/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/fi/fi/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/fi/fi/additional-privacy-information',
  },
];

const frFRTopLinks = [
  {
    text: 'Mentions légales.',
    linkUrl: 'https://connect-store2.porsche.com/fr/fr/t/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/fr/fr/business_human_rights',
  },
  {
    text: 'Conditions générales.',
    linkUrl: 'https://connect-store2.porsche.com/fr/fr/t/termsandconditions',
  },
  {
    text: 'Politique des cookies.',
    linkUrl: 'https://connect-store.porsche.com/fr/fr/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: `${window.location.origin}/licenses`,
  },
  {
    text: 'Date de confidentialité.',
    linkUrl: 'https://connect-store2.porsche.com/fr/fr/t/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/fr/fr/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/fr/fr/additional-privacy-information',
  },
];

const huHUTopLinks = [
  {
    text: 'Legal Notice.',
    linkUrl: 'https://connect-store.porsche.com/hu/en/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/hu/en/business_human_rights',
  },
  {
    text: 'Terms and Conditions.',
    linkUrl: 'https://connect-store.porsche.com/hu/en/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl: 'https://connect-store.porsche.com/hu/en/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Data Privacy.',
    linkUrl: 'https://connect-store.porsche.com/hu/en/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/hu/en/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/hu/en/additional-privacy-information',
  },
];

const frLUTopLinks = [
  {
    text: 'Mentions légales.',
    linkUrl: 'https://connect-store.porsche.com/lu/fr/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/lu/fr/business_human_rights',
  },
  {
    text: 'Conditions générales.',
    linkUrl: 'https://connect-store.porsche.com/lu/fr/termsandconditions',
  },
  {
    text: 'Politique des cookies.',
    linkUrl: 'https://connect-store.porsche.com/lu/fr/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Date de confidentialité.',
    linkUrl: 'https://connect-store.porsche.com/lu/fr/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/lu/fr/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/lu/fr/additional-privacy-information',
  },
];

const deLUTopLinks = [
  {
    text: 'Impressum und Rechtliche Hinweise.',
    linkUrl: 'https://connect-store.porsche.com/lu/de/about',
  },
  {
    text: 'Wirtschaft und Menschenrechte.',
    linkUrl: 'https://connect-store.porsche.com/lu/de/business_human_rights',
  },
  {
    text: 'AGB.',
    linkUrl: 'https://connect-store.porsche.com/lu/de/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl: 'https://connect-store.porsche.com/lu/de/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Hinweise zum Datenschutz.',
    linkUrl: 'https://connect-store.porsche.com/lu/de/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/lu/de/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/lu/de/additional-privacy-information',
  },
];

const frCHTopLinks = [
  {
    text: 'Mentions légales.',
    linkUrl: 'https://connect-store.porsche.com/ch/fr/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/ch/fr/business_human_rights',
  },
  {
    text: 'Conditions générales.',
    linkUrl: 'https://connect-store.porsche.com/ch/fr/termsandconditions',
  },
  {
    text: 'Politique des cookies.',
    linkUrl: 'https://connect-store.porsche.com/ch/fr/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Date de confidentialité.',
    linkUrl: 'https://connect-store.porsche.com/ch/fr/privacy',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/ch/fr/additional-privacy-information',
  },
];

const itCHTopLinks = [
  {
    text: 'Note legali.',
    linkUrl: 'https://connect-store.porsche.com/ch/it/about',
  },
  {
    text: 'Condizioni generali di contratto.',
    linkUrl: 'https://connect-store.porsche.com/ch/it/termsandconditions',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/ch/it/business_human_rights',
  },
  {
    text: 'Politica dei cookie.',
    linkUrl: 'https://connect-store.porsche.com/ch/it/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Note sulla protezione dei dati.',
    linkUrl: 'https://connect-store.porsche.com/ch/it/privacy',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/ch/it/additional-privacy-information',
  },
];

const enCHTopLinks = [
  {
    text: 'Legal Notice.',
    linkUrl: 'https://connect-store.porsche.com/offer/ch/en-CH/static/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl:
      'https://connect-store.porsche.com/offer/ch/en-CH/static/business_human_rights',
  },
  {
    text: 'Terms and Conditions.',
    linkUrl:
      'https://connect-store.porsche.com/offer/ch/en-CH/static/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl: 'https://connect-store.porsche.com/ch/en/cookiepolicy',
  },
  {
    text: 'Open Soursce Software Notice.',
    linkUrl: 'https://www.porsche.com/licenses/',
  },
  {
    text: 'Data Privacy.',
    linkUrl: 'https://connect-store.porsche.com/offer/ch/en-CH/static/privacy',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/offer/ch/en-CH/static/additional-privacy-information',
  },
  {
    text: 'Information on data protection for Connect Services.',
    linkUrl:
      'https://connect-store.porsche.com/offer/ch/en-CH/static/dppconnect',
  },
];

const enSKTopLinks = [
  {
    text: 'Legal Notice.',
    linkUrl: 'https://connect-store.porsche.com/offer/sk/en-SK/static/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl:
      'https://connect-store.porsche.com/offer/sk/en-SK/static/business_human_rights',
  },
  {
    text: 'Terms and Conditions.',
    linkUrl:
      'https://connect-store.porsche.com/offer/sk/en-SK/static/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl: 'https://connect-store.porsche.com/sk/en/cookiepolicy',
  },
  {
    text: 'Open Soursce Software Notice.',
    linkUrl: 'https://www.porsche.com/licenses/',
  },
  {
    text: 'Data Privacy.',
    linkUrl: 'https://connect-store.porsche.com/offer/sk/en-SK/static/privacy',
  },
  {
    text: 'Digital Services act.',
    linkUrl:
      'https://connect-store.porsche.com/offer/sk/en-SK/static/digital_services_act',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/offer/sk/en-SK/static/additional-privacy-information',
  },
  {
    text: 'Information on data protection for Connect Services.',
    linkUrl:
      'https://connect-store.porsche.com/offer/sk/en-SK/static/dppconnect',
  },
];

const enLTTopLinks = [
  {
    text: 'Legal Notice.',
    linkUrl: 'https://connect-store.porsche.com/offer/lt/en-LT/static/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl:
      'https://connect-store.porsche.com/offer/lt/en-LT/static/business_human_rights',
  },
  {
    text: 'Terms and Conditions.',
    linkUrl:
      'https://connect-store.porsche.com/offer/lt/en-LT/static/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl: 'https://connect-store.porsche.com/lt/en/cookiepolicy',
  },
  {
    text: 'Open Soursce Software Notice.',
    linkUrl: 'https://www.porsche.com/licenses/',
  },
  {
    text: 'Data Privacy.',
    linkUrl: 'https://connect-store.porsche.com/offer/lt/en-LT/static/privacy',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/offer/lt/en-LT/static/additional-privacy-information',
  },
  {
    text: 'Information on data protection for Connect Services.',
    linkUrl:
      'https://connect-store.porsche.com/offer/lt/en-LT/static/dppconnect',
  },
];

const enFITopLinks = [
  {
    text: 'Legal Notice.',
    linkUrl: 'https://connect-store.porsche.com/offer/fi/en-FI/static/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl:
      'https://connect-store.porsche.com/offer/fi/en-FI/static/business_human_rights',
  },
  {
    text: 'Terms and Conditions.',
    linkUrl:
      'https://connect-store.porsche.com/offer/fi/en-FI/static/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl:
      'https://connect-store.porsche.com/offer/fi/en-FI/static/cookiepolicy',
  },
  {
    text: 'Open Soursce Software Notice.',
    linkUrl: 'https://www.porsche.com/licenses/',
  },
  {
    text: 'Data Privacy.',
    linkUrl: 'https://connect-store.porsche.com/offer/fi/en-FI/static/privacy',
  },
  {
    text: 'Digital Services act.',
    linkUrl:
      'https://connect-store.porsche.com/offer/fi/en-FI/static/digital_services_act',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/offer/fi/en-FI/static/additional-privacy-information',
  },
  {
    text: 'Information on data protection for Connect Services.',
    linkUrl:
      'https://connect-store.porsche.com/offer/fi/en-FI/static/dppconnect',
  },
];

const enSITopLinks = [
  {
    text: 'Legal Notice.',
    linkUrl: 'https://connect-store.porsche.com/offer/si/en-SI/static/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl:
      'https://connect-store.porsche.com/offer/si/en-SI/static/business_human_rights',
  },
  {
    text: 'Terms and Conditions.',
    linkUrl:
      'https://connect-store.porsche.com/offer/si/en-SI/static/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl:
      'https://connect-store.porsche.com/offer/si/en/static/cookiepolicy',
  },
  {
    text: 'Open Soursce Software Notice.',
    linkUrl: 'https://www.porsche.com/licenses/',
  },
  {
    text: 'Data Privacy.',
    linkUrl: 'https://connect-store.porsche.com/offer/si/en-SI/static/privacy',
  },
  {
    text: 'Digital Services act.',
    linkUrl:
      'https://connect-store.porsche.com/offer/si/en-SI/static/digital_services_act',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/offer/si/en-SI/static/additional-privacy-information',
  },
  {
    text: 'Information on data protection for Connect Services.',
    linkUrl:
      'https://connect-store.porsche.com/offer/si/en-SI/static/dppconnect',
  },
];

const enHRTopLinks = [
  {
    text: 'Legal Notice.',
    linkUrl: 'https://connect-store.porsche.com/offer/hr/en-HR/static/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl:
      'https://connect-store.porsche.com/offer/hr/en-HR/static/business_human_rights',
  },
  {
    text: 'Terms and Conditions.',
    linkUrl:
      'https://connect-store.porsche.com/offer/hr/en-HR/static/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl:
      'https://connect-store.porsche.com/offer/hr/en-HR/static/cookiepolicy',
  },
  {
    text: 'Open Soursce Software Notice.',
    linkUrl: 'https://www.porsche.com/licenses/',
  },
  {
    text: 'Data Privacy.',
    linkUrl: 'https://connect-store.porsche.com/offer/hr/en-HR/static/privacy',
  },
  {
    text: 'Digital Services act.',
    linkUrl:
      'https://connect-store.porsche.com/offer/hr/en-HR/static/digital_services_act',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/offer/hr/en-HR/static/additional-privacy-information',
  },
  {
    text: 'Information on data protection for Connect Services.',
    linkUrl:
      'https://connect-store.porsche.com/offer/hr/en-HR/static/dppconnect',
  },
];

const enUSTopLinks = [
  {
    text: 'Legal Notice.',
    linkUrl: 'https://connect-store.porsche.com/us/en/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/us/en/business_human_rights',
  },
  {
    text: 'Terms and Conditions.',
    linkUrl: 'https://connect-store.porsche.com/us/en/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl: 'https://connect-store.porsche.com/us/en/privacy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Privacy Notice.',
    linkUrl: 'https://connect-store.porsche.com/us/en/privacy',
  },
  {
    text: 'California Privacy.',
    linkUrl:
      'https://connect-store2.porsche.com/us/en/t/privacy?reducedHeaderFooter=true#california-consumers-notice',
  },
  {
    text: 'Do not sell or share my personal information.',
    linkUrl: 'https://www.porsche.com/usa/privacy-policy/contact/',
  },
];

const frBETopLinks = [
  {
    text: 'Mentions légales.',
    linkUrl: 'https://connect-store.porsche.com/be/fr/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/be/fr/business_human_rights',
  },
  {
    text: 'Conditions générales.',
    linkUrl: 'https://connect-store.porsche.com/be/fr/termsandconditions',
  },
  {
    text: 'Politique des cookies.',
    linkUrl: 'https://connect-store.porsche.com/be/fr/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Date de confidentialité.',
    linkUrl: 'https://connect-store.porsche.com/be/fr/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/be/fr/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/be/fr/additional-privacy-information',
  },
];

const frCATopLinks = [
  {
    text: 'Mentions légales.',
    linkUrl: 'https://connect-store.porsche.com/ca/fr/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/ca/fr/business_human_rights',
  },
  {
    text: 'Conditions générales.',
    linkUrl: 'https://connect-store.porsche.com/ca/fr/termsandconditions',
  },
  {
    text: 'Politique des cookies.',
    linkUrl: 'https://connect-store.porsche.com/ca/fr/privacy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Date de confidentialité.',
    linkUrl: 'https://connect-store.porsche.com/ca/fr/privacy',
  },
];

const itITTopLinks = [
  {
    text: 'Note legali.',
    linkUrl: 'https://connect-store.porsche.com/it/it/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/it/it/business_human_rights',
  },
  {
    text: 'Condizioni generali di contratto.',
    linkUrl: 'https://connect-store.porsche.com/it/it/termsandconditions',
  },
  {
    text: 'Politica dei cookie.',
    linkUrl: 'https://connect-store.porsche.com/it/it/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Note sulla protezione dei dati.',
    linkUrl: 'https://connect-store.porsche.com/it/it/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/it/it/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/it/it/additional-privacy-information',
  },
];

const noNOTopLinks = [
  {
    text: 'Impressum og juridiske merknader.',
    linkUrl: 'https://connect-store.porsche.com/no/no/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/no/no/business_human_rights',
  },
  {
    text: 'Vilkår og betingelser.',
    linkUrl: 'https://connect-store.porsche.com/no/no/termsandconditions',
  },
  {
    text: 'Retningslinjer for informasjonskapsler.',
    linkUrl: 'https://connect-store.porsche.com/no/no/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: `${window.location.origin}/licenses`,
  },
  {
    text: 'Merknader for personvern.',
    linkUrl: 'https://connect-store.porsche.com/no/no/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/no/no/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/no/no/additional-privacy-information',
  },
];

const ptPTTopLinks = [
  {
    text: 'Aviso legal.',
    linkUrl: 'https://connect-store.porsche.com/pt/pt/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/pt/pt/business_human_rights',
  },
  {
    text: 'Termos e condicoes gerais.',
    linkUrl: 'https://connect-store.porsche.com/pt/pt/termsandconditions',
  },
  {
    text: 'Política de cookies.',
    linkUrl: 'https://connect-store.porsche.com/pt/pt/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Notificação de protecção de dados.',
    linkUrl: 'https://connect-store.porsche.com/pt/pt/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/pt/pt/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/pt/pt/additional-privacy-information',
  },
];

const plPLTopLinks = [
  {
    text: 'Stopka oraz informacje prawne.',
    linkUrl: 'https://connect-store.porsche.com/pl/pl/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/pl/pl/business_human_rights',
  },
  {
    text: 'Ogólne warunki handlowe.',
    linkUrl: 'https://connect-store.porsche.com/pl/pl/termsandconditions',
  },
  {
    text: 'Polityka plików cookie.',
    linkUrl: 'https://connect-store.porsche.com/pl/pl/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Informacje o ochronie danych osobowych.',
    linkUrl: 'https://connect-store.porsche.com/pl/pl/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/pl/pl/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/pl/pl/additional-privacy-information',
  },
];

const skSKTopLinks = [
  {
    text: 'Legal Notice.',
    linkUrl: 'https://connect-store.porsche.com/sk/en/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/sk/en/business_human_rights',
  },
  {
    text: 'Terms and Conditions.',
    linkUrl: 'https://connect-store.porsche.com/sk/en/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl: 'https://connect-store.porsche.com/sk/en/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Data Privacy.',
    linkUrl: 'https://connect-store.porsche.com/sk/en/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/sk/en/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/sk/en/additional-privacy-information',
  },
];

const slSITopLinks = [
  {
    text: 'Legal Notice.',
    linkUrl: 'https://connect-store.porsche.com/si/en/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/si/en/business_human_rights',
  },
  {
    text: 'Terms and Conditions.',
    linkUrl: 'https://connect-store.porsche.com/si/en/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl: 'https://connect-store.porsche.com/si/en/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Data Privacy.',
    linkUrl: 'https://connect-store.porsche.com/si/en/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/si/en/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/si/en/additional-privacy-information',
  },
];

const ltLTTopLinks = [
  {
    text: 'Autorių teisės ir teisinė informacija.',
    linkUrl: 'https://connect-store.porsche.com/lt/lt/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/lt/lt/business_human_rights',
  },
  {
    text: 'BVS.',
    linkUrl: 'https://connect-store.porsche.com/lt/lt/termsandconditions',
  },
  {
    text: 'Slapukų politika.',
    linkUrl: 'https://connect-store.porsche.com/lt/lt/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Nurodymai dėl duomenų apsaugos.',
    linkUrl: 'https://connect-store.porsche.com/lt/lt/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/lt/lt/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/lt/lt/additional-privacy-information',
  },
];

const lvLVTopLinks = [
  {
    text: 'Kontakti un juridiskas norādes.',
    linkUrl: 'https://connect-store.porsche.com/lv/lv/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/lv/lv/business_human_rights',
  },
  {
    text: 'VLN.',
    linkUrl: 'https://connect-store.porsche.com/lv/lv/termsandconditions',
  },
  {
    text: 'Sīkdatņu politika.',
    linkUrl: 'https://connect-store.porsche.com/lv/lv/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Norādījumi par datu aizsardzību.',
    linkUrl: 'https://connect-store.porsche.com/lv/lv/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/lv/lv/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/lv/lv/additional-privacy-information',
  },
];

const deLITopLinks = [
  {
    text: 'Impressum und Rechtliche Hinweise.',
    linkUrl: 'https://connect-store.porsche.com/li/de/about',
  },
  {
    text: 'Wirtschaft und Menschenrechte.',
    linkUrl: 'https://connect-store.porsche.com/li/de/business_human_rights',
  },
  {
    text: 'AGB.',
    linkUrl: 'https://connect-store.porsche.com/li/de/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl: 'https://connect-store.porsche.com/li/de/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Hinweise zum Datenschutz.',
    linkUrl: 'https://connect-store.porsche.com/li/de/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/li/de/digital_services_act?model=',
  },
  {
    text: 'Ergänzende Datenschutzinformationen.',
    linkUrl:
      'https://connect-store.porsche.com/li/de/additional-privacy-information',
  },
];

const svSETopLinks = [
  {
    text: 'Företagsinformation och rättslig information.',
    linkUrl: 'https://connect-store.porsche.com/se/sv/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/se/sv/business_human_rights',
  },
  {
    text: 'Allmänna affärsvillkor.',
    linkUrl: 'https://connect-store.porsche.com/se/sv/termsandconditions',
  },
  {
    text: 'Cookiepolicy.',
    linkUrl: 'https://connect-store.porsche.com/se/sv/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Information om sekretesskydd.',
    linkUrl: 'https://connect-store.porsche.com/se/sv/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/se/sv/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/se/sv/additional-privacy-information',
  },
];

const hrHRTopLinks = [
  {
    text: 'Legal Notice.',
    linkUrl: 'https://connect-store.porsche.com/hr/en/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/hr/en/business_human_rights',
  },
  {
    text: 'Terms and Conditions.',
    linkUrl: 'https://connect-store.porsche.com/hr/en/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl: 'https://connect-store.porsche.com/hr/en/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Data Privacy.',
    linkUrl: 'https://connect-store.porsche.com/hr/en/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/hr/en/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/hr/en/additional-privacy-information',
  },
];

const enIETopLinks = [
  {
    text: 'Legal Notice.',
    linkUrl: 'https://connect-store.porsche.com/ie/en/about',
  },
  {
    text: 'Business and Human Rights.',
    linkUrl: 'https://connect-store.porsche.com/ie/en/business_human_rights',
  },
  {
    text: 'Terms and Conditions.',
    linkUrl: 'https://connect-store.porsche.com/ie/en/termsandconditions',
  },
  {
    text: 'Cookie Policy.',
    linkUrl: 'https://connect-store.porsche.com/ie/en/cookiepolicy',
  },
  {
    text: 'Open Source Software Notice.',
    linkUrl: 'https://porsche.com/licenses',
  },
  {
    text: 'Data Privacy.',
    linkUrl: 'https://connect-store.porsche.com/ie/en/privacy',
  },
  {
    text: 'Digital Services Act.',
    linkUrl:
      'https://connect-store.porsche.com/ie/en/digital_services_act?model=',
  },
  {
    text: 'Additional Privacy Information.',
    linkUrl:
      'https://connect-store.porsche.com/ie/en/additional-privacy-information',
  },
];

export const useFooter = () => {
  const { locale } = useParams();

  const contentByLocaleMap = {
    'de-DE': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(deDETopLinks)}
        </div>

        <div className={style.longText}>
          Soweit die angegebenen Verbrauchs- und Emissionswerte als Spannen
          angegeben werden, beziehen sie sich nicht auf ein einzelnes,
          individuelles Fahrzeug und sind nicht Bestandteil des Angebots. Sie
          dienen allein Vergleichszwecken zwischen den verschiedenen
          Fahrzeugtypen. Zusatzausstattungen und Zubehör (Anbauteile,
          Reifenformat usw.) können relevante Fahrzeugparameter wie z.B.
          Gewicht, Rollwiderstand und Aerodynamik verändern und neben
          Witterungs- und Verkehrsbedingungen sowie dem individuellen
          Fahrverhalten den Kraftstoff-/Stromverbrauch, die CO₂-Emissionen, die
          Reichweite und die Fahrleistungswerte eines Fahrzeugs beeinflussen.
        </div>

        <div className={style.longText}>
          Wichtige Hinweise zu den vollelektrischen Porsche Modellen finden Sie{' '}
          <a
            target="__blank"
            href="https://www.porsche.com/germany/accessoriesandservices/porscheservice/vehicleinformation/bev/"
            className={style.anchor}
          >
            {' '}
            hier
          </a>
          .
        </div>
      </>
    ),
    'en-GB': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(enGBTopLinks)}
        </div>

        <div className={style.longText}>
          Neither the number of active users of the Porsche Connect Store, nor
          the number of active users of the App Centre available in the
          connect-capable vehicle (Macan electric) when the Connect services are
          activated exceeds the number set by the Digital Service Act (DSA)
          threshold of 45 million active users in the European Union.
        </div>
      </>
    ),
    'en-CA': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace Canada, Ltd.
          </div>
          {createLinksFromArray(enCATopLinks)}
        </div>
      </>
    ),
    'fr-CA': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace Canada, Ltd.
          </div>
          {createLinksFromArray(frCATopLinks)}
        </div>
      </>
    ),
    'de-AT': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(deATTopLinks)}
        </div>
      </>
    ),
    'nl-NL': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(nlNLTopLinks)}
        </div>
      </>
    ),
    'nl-BE': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(nlBETopLinks)}
        </div>
      </>
    ),
    'cs-CZ': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(csCZTopLinks)}
        </div>
      </>
    ),
    'de-CH': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(deCHTopLinks)}
        </div>

        <div className={style.longText}>
          Weder die Anzahl der aktiven Nutzer dieses Porsche Connect Stores noch
          die Anzahl der aktiven Nutzer des im connectfähigen Fahrzeug (Macan
          electric) bei Aktivierung der Connect Dienste verfügbaren App Centers
          überschreitet den vom Digital Service Act (DSA) vorgegebenen
          Schwellenwert von 45 Millionen aktiven Nutzern in der europäischen
          Union.
        </div>
      </>
    ),
    'da-DK': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(daDKTopLinks)}
        </div>
      </>
    ),
    'et-EE': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(etEETopLinks)}
        </div>
      </>
    ),
    'es-ES': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(esESTopLinks)}
        </div>
      </>
    ),
    'fi-FI': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(fiFITopLinks)}
        </div>
      </>
    ),
    'fr-FR': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(frFRTopLinks)}
        </div>
      </>
    ),
    'hu-HU': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(huHUTopLinks)}
        </div>
      </>
    ),
    'fr-LU': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(frLUTopLinks)}
        </div>
      </>
    ),
    'de-LU': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(deLUTopLinks)}
        </div>
      </>
    ),
    'fr-CH': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(frCHTopLinks)}
        </div>

        <div className={style.longText}>
          Ni le nombre d&apos;utilisateurs actifs de ce Porsche Connect Store ni
          le nombre d&apos;utilisateurs actifs de l&apos;App Center disponible
          dans le véhicule compatible avec Porsche Connect (Macan électrique)
          lors de l&apos;activation des services Connect ne dépassent la valeur
          seuil fixée par le Digital Service Act (DSA) de 45 millions
          d&apos;utilisateurs actifs dans l&apos;Union européenne.
        </div>
      </>
    ),
    'en-CH': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(enCHTopLinks)}
        </div>

        <div className={style.longText}>
          Neither the number of active users of the Porsche Connect Store, nor
          the number of active users of the App Centre available in the
          connect-capable vehicle (Macan electric) when the Connect services are
          activated exceeds the number set by the Digital Service Act (DSA)
          threshold of 45 million active users in the European Union.
        </div>
      </>
    ),
    'en-HR': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(enHRTopLinks)}
        </div>
      </>
    ),
    'en-SI': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(enSITopLinks)}
        </div>
      </>
    ),
    'en-LT': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(enLTTopLinks)}
        </div>
      </>
    ),
    'en-SK': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(enSKTopLinks)}
        </div>
      </>
    ),
    'en-FI': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(enFITopLinks)}
        </div>
      </>
    ),
    'it-CH': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(itCHTopLinks)}
        </div>

        <div className={style.longText}>
          Il numero di utenti attivi di questo Porsche Connect Store e il numero
          di utenti attivi dell&apos;App Center disponibile nella vettura
          compatibile con Connect (Macan electric) all&apos;attivazione dei
          servizi Connect non superano la soglia di 45 milioni di utenti attivi
          nell&apos;Unione Europea stabilita dal Digital Service Act (DSA).
        </div>
      </>
    ),
    'en-US': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace, Inc.
          </div>
          {createLinksFromArray(enUSTopLinks)}
        </div>

        <div className={style.longText}>Accessibility Statement:</div>

        <div className={style.longText}>
          If you experience any difficulty in accessing any part of this
          website, please feel free to contact us at 1-800-PORSCHE or{' '}
          <a
            className={style.anchor}
            target="__blank"
            href="mailto:accessibility@porsche.us"
          >
            accessibility@porsche.us
          </a>
          .
        </div>

        <div className={style.longText}>
          Porsche is committed to making its websites usable by all people by
          meeting or exceeding the requirements of the Web Content Accessibility
          Guidelines 2.0 Level AA (the Guidelines). We continually assess and
          work to ensure that our Web presence is in conformance with the
          Guidelines. Please be aware that our efforts are ongoing as our
          current website provider implements the relevant improvements to meet
          the Guidelines over time. If you experience any difficulty in
          accessing any part of this website, please feel free to contact us at
          1-800-PORSCHE or{' '}
          <a
            className={style.anchor}
            target="__blank"
            href="mailto:accessibility@porsche.us"
          >
            accessibility@porsche.us
          </a>
          . Please be sure to specify the Web page and describe the issue in
          detail and we will make reasonable efforts to make that page
          accessible. We welcome feedback on how we can improve as well.
        </div>
      </>
    ),
    'fr-BE': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(frBETopLinks)}
        </div>
      </>
    ),
    'it-IT': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(itITTopLinks)}
        </div>
      </>
    ),
    'no-NO': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(noNOTopLinks)}
        </div>
      </>
    ),
    'pt-PT': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(ptPTTopLinks)}
        </div>
      </>
    ),
    'pl-PL': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(plPLTopLinks)}
        </div>
      </>
    ),
    'sk-SK': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(skSKTopLinks)}
        </div>
      </>
    ),
    'sl-SI': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(slSITopLinks)}
        </div>
      </>
    ),
    'lt-LT': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(ltLTTopLinks)}
        </div>
      </>
    ),
    'lv-LV': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(lvLVTopLinks)}
        </div>
      </>
    ),
    'de-LI': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(deLITopLinks)}
        </div>
      </>
    ),
    'sv-SE': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(svSETopLinks)}
        </div>
      </>
    ),
    'hr-HR': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(hrHRTopLinks)}
        </div>
      </>
    ),
    'en-IE': (
      <>
        <div className={style.elementsContainer}>
          <div className={style.normalText}>
            {copyrightText} Porsche Sales & Marketplace GmbH.
          </div>
          {createLinksFromArray(enIETopLinks)}
        </div>
      </>
    ),
  } as { [key: string]: JSX.Element };

  return !!locale && contentByLocaleMap[locale]
    ? contentByLocaleMap[locale]
    : contentByLocaleMap['de-DE'];
};
