import { useEffect, useState } from 'react';

const cookieConsentIntervals = {} as {
  [service: string]: ReturnType<typeof setInterval> | undefined;
};

export const useCookieConsent = (service: string) => {
  const [cookieConsentInteracted, setCookieConsentInteracted] = useState(false);
  const [hasCookieConsent, setHasCookieConsent] = useState(false);

  useEffect(() => {
    let isConsented = false;

    if (cookieConsentInteracted && window.usercentrics) {
      try {
        const consent = window.usercentrics
          .getConsents()
          .find(
            ({ dataProcessingService }) => dataProcessingService === service,
          );

        if (consent?.consentStatus) {
          isConsented = true;
        }
      } catch (error) {
        console.error('Consents not found', error);
      }
    }

    setHasCookieConsent(isConsented);
  }, [service, cookieConsentInteracted]);

  useEffect(() => {
    cookieConsentIntervals[service] = setInterval(() => {
      if (localStorage.getItem('uc_user_interaction') === 'true') {
        setCookieConsentInteracted(true);
        clearInterval(cookieConsentIntervals[service]);
      }
    }, 100);

    return () => clearInterval(cookieConsentIntervals[service]);
  }, []);

  return {
    cookieConsentInteracted,
    hasCookieConsent,
  };
};
