import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  PButton,
  PInlineNotification,
  PText,
} from '@porsche-design-system/components-react';

import style from '../../invitation.module.scss';

import { messages } from '../../invitationPage.messages';
import { useGetConnectRedirectionUrl } from 'pages/invitation/getConnectRedirectionUrl';

interface IProps {
  canSubmit: boolean;
  isSubmitDisabled: boolean;
  isLoading: boolean;
}

const SubmitSection: React.FC<IProps> = ({
  canSubmit,
  isSubmitDisabled,
  isLoading,
}) => {
  const [isOnline, setIsOnline] = useState(true);

  const redirectionUrl = useGetConnectRedirectionUrl();

  useState(false);

  const intl = useIntl();

  useEffect(() => {
    const setOnline = (_e: Event) => {
      setIsOnline(true);
    };
    const setOffline = (_e: Event) => {
      setIsOnline(false);
    };

    window.addEventListener('offline', setOffline);

    window.addEventListener('online', setOnline);

    return () => {
      window.removeEventListener('offline', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return (
    <>
      {isOnline ? (
        <div className={style.submitWrapper} style={{ position: 'relative' }}>
          <div>
            <PButton
              className={`${style.pr2} ${style.pb2} ${style.fullWidthButton}`}
              type={canSubmit ? 'submit' : 'button'}
              disabled={isSubmitDisabled}
              loading={isLoading}
            >
              <FormattedMessage {...messages.submitButton} />
            </PButton>
            <a href={redirectionUrl.redirectionIfNoChargingOrder.toString()}>
              <PButton variant="secondary" type="button">
                <FormattedMessage {...messages.skipButton} />
              </PButton>
            </a>
            {!canSubmit && (
              <div className={style.popOver}>
                <PText>
                  <FormattedMessage {...messages.acceptToCMessage} />
                </PText>
              </div>
            )}
          </div>
        </div>
      ) : (
        <PInlineNotification
          dismissButton={false}
          heading={intl.formatMessage(messages.offlineErrorTitle)}
          description={intl.formatMessage(messages.offlineErrorBody)}
          state="error"
        />
      )}
    </>
  );
};

export default SubmitSection;
