import { PButton, PSpinner } from '@porsche-design-system/components-react';
import { FC, PropsWithChildren, useEffect, useMemo } from 'react';

import { PaymentData, usePaymentWidget } from './usePaymentWidget';
import style from '../../checkout.module.scss';

interface PaymentWidgetProps {
  apiKey: string;
  authorizationToken: string;
  marketplace: string;
  defaultPaymentMethodId?: string;
  locale: string;
  onChange?: (paymentData: PaymentData) => void;
  handleApplePayTokenSave?: (token: string) => void;
  redirectUrl?: string;
  isPayPalSelected?: boolean;
  shouldUseIntegratedLogic?: boolean;
  onChangePaymentId?: (paimentMethodId: string) => void;
  currency?: string;
  isTransparent?: boolean;
}
const AssortmentsWithAmounts = JSON.stringify([
  {
    assortment: 'CHARGING',
    amount: 0,
  },
]);

let hackyInterval: ReturnType<typeof setInterval> | undefined;

export const PaymentWidget: FC<PropsWithChildren<PaymentWidgetProps>> = ({
  apiKey,
  authorizationToken,
  marketplace,
  defaultPaymentMethodId,
  locale,
  onChange,
  redirectUrl,
  isPayPalSelected = false,
  onChangePaymentId,
  shouldUseIntegratedLogic,
  currency = 'EUR',
  isTransparent = false,
  handleApplePayTokenSave,
}) => {
  const {
    defaultPaymentOption,
    handleSubmit,
    isWidgetLoading,
    paymentStatus,
    shouldShowSignInButton,
    widgetRef,
    paymentButtonWidgetRef,
  } = usePaymentWidget({
    apiKey,
    defaultPaymentMethodId,
    isPayPalSelected,
    handleApplePayTokenSave,
    onChange,
    onChangePaymentId,
    shouldUseIntegratedLogic,
    token: authorizationToken,
  });

  const normalizedLocale = useMemo(() => getNormalizedLocal(locale!), [locale]);

  useEffect(() => {
    clearInterval(hackyInterval);

    hackyInterval = isTransparent
      ? setInterval(() => {
          const widgetElement = document
            .querySelector<HTMLElement>('#payment-widget')
            ?.shadowRoot?.querySelector<HTMLElement>('.payment-widget');

          if (widgetElement) {
            clearInterval(hackyInterval);
            widgetElement.style.backgroundColor = 'transparent';
          }
        }, 10)
      : undefined;

    return () => {
      clearInterval(hackyInterval);
    };
  }, [isTransparent]);

  const environment = useMemo(
    () =>
      process.env.REACT_APP_ENV !== 'prod' ? 'preproduction' : 'production',
    [],
  );

  if (isWidgetLoading) {
    return <PSpinner />;
  }

  return (
    <>
      <payment-widget
        apikey={apiKey}
        assortmentswithamounts={AssortmentsWithAmounts}
        countrycode={marketplace}
        currency={currency}
        disableautofocus
        disableautoscroll
        environment={environment}
        hidecardsectiontitle
        id="payment-widget"
        keeppadding={false}
        locale={normalizedLocale}
        nextbuttonexplicithandling
        oauthtoken={authorizationToken}
        redirecturl={redirectUrl || window.location.href}
        ref={widgetRef}
        selectedpaymentmethod={defaultPaymentOption}
        skipdoubleauthentication={false}
      />
      {handleApplePayTokenSave ? (
        <payment-button-widget
          redirecturl={redirectUrl || window.location.href}
          ref={paymentButtonWidgetRef}
          apikey={apiKey}
          assortmentswithamounts={JSON.stringify([
            { assortment: 'CHARGING', amount: 0 },
          ])}
          environment={environment}
          countrycode={marketplace}
          locale={locale}
          currency={currency}
          guest="false"
          eventdata={paymentStatus ? JSON.stringify(paymentStatus) : undefined}
          buttontype="buttonType"
          buttoncolor="buttonColor"
        />
      ) : null}
      {shouldShowSignInButton && (
        <div className={style.mt2}>
          <PButton
            type="button"
            onClick={handleSubmit}
            disabled={!shouldShowSignInButton}
          >
            Login to PayPal
          </PButton>
        </div>
      )}
    </>
  );
};

const getNormalizedLocal = (locale: string) => {
  switch (locale.replace('_', '-')) {
    case 'de-AT':
    case 'de-CH':
    case 'de-LI':
    case 'de-LU':
      return 'de-DE';
    case 'fr-CH':
    case 'fr-MC':
    case 'fr-LU':
    case 'fr-BE':
    case 'fr-CA':
      return 'fr-FR';
    case 'es-AD':
      return 'es-ES';
    case 'it-CH':
    case 'it-LT':
      return 'it-IT';
    case 'nl-BE':
      return 'nl-NL';
    case 'en-BG':
    case 'en-CA':
    case 'en-CH':
    case 'en-CY':
    case 'en-EE':
    case 'en-FI':
    case 'en-GB':
    case 'en-GI':
    case 'en-GR':
    case 'en-HR':
    case 'en-IE':
    case 'en-IS':
    case 'en-LT':
    case 'en-LV':
    case 'en-MT':
    case 'en-RO':
    case 'en-SI':
    case 'en-SK':
    case 'et-EE':
    case 'lv-LV':
      return 'en-US';
    default:
      return locale.replace('_', '-');
  }
};
