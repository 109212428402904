import {
  PButton,
  PHeading,
  PIcon,
  PSpinner,
  PTag,
  PText,
} from '@porsche-design-system/components-react';
import { RawPlan } from 'components/context/plan/planContext.types';
import { InlineTariff } from 'components/tariff/inline/InlineTariff';
import { useRoutes } from 'hooks/useRoutes';
import { useShouldShowPriceUpdate } from 'hooks/useShouldShowPriceUpdate';
import { useSpecificPlans } from 'hooks/useSpecificPlans';
import { observer } from 'mobx-react-lite';
import { AdditionalDisclaimer } from 'pages/change/select/additionalDisclaimer/AdditionalDisclaimer';
import { FC, useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'router/routes';

import { messages } from './priceAcceptPage.messages';
import style from './priceAcceptPage.module.scss';
import { subscriptionContext } from '../../../components/context/subscription/SubscriptionContext';
import { FrostedLayout } from '../../../components/layout/frosted/FrostedLayout';
import {
  NetworkStatus,
  useCombinedNetworking,
} from '../../../hooks/useCombinedNetworking/useCombinedNetworking';
import background from '../../../media/backgroundPriceUpdate.png';

export const PriceAcceptPage: FC = observer(() => {
  const navigate = useNavigate();
  const generateRoute = useRoutes();
  const subscription = useContext(subscriptionContext);
  const { plans, isLoading: isLoadingPlans } = useSpecificPlans({
    planVariant: subscription.subscription?.currentOrLastPhase.plan.variant,
  });

  const { dateOfPriceUpdate } = useShouldShowPriceUpdate({
    subscription: subscription?.subscription,
  });

  const formattedDate =
    dateOfPriceUpdate &&
    Intl.DateTimeFormat('default', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }).format(new Date(dateOfPriceUpdate));

  const network = useCombinedNetworking([subscription.network]);

  const loadingText = useMemo(() => {
    if (subscription.network !== NetworkStatus.Success) {
      return <FormattedMessage {...messages.loadingSubscription} />;
    }
    return <FormattedMessage {...messages.loadingTariffs} />;
  }, [subscription.network]);

  const content = (
    <div>
      {network === NetworkStatus.Loading || isLoadingPlans ? (
        <div className={style.center}>
          <PSpinner />
          <PText>{loadingText}</PText>
        </div>
      ) : null}

      {network === NetworkStatus.Error || subscription.subscription === null ? (
        <div className={style.center}>
          <PIcon
            name={'close'}
            size={'large'}
            aria={{ 'aria-label': 'Close icon' }}
          />
          <PText size={'medium'}>
            <FormattedMessage {...messages.errorTitle} />
          </PText>
          <PText size={'small'}>
            <FormattedMessage {...messages.errorText} />
          </PText>
        </div>
      ) : null}

      {!subscription.subscription && network === NetworkStatus.Success ? (
        <div className={style.center}>
          <PIcon
            name={'check'}
            size={'large'}
            aria={{ 'aria-label': 'Check icon' }}
          />
          <PText size={'medium'}>
            <FormattedMessage {...messages.unavailableTitle} />
          </PText>
          <PText size={'small'}>
            <FormattedMessage {...messages.unavailableText} />
          </PText>
        </div>
      ) : null}

      {subscription && subscription.subscription ? (
        <div className={style.contentContainer}>
          <PHeading>
            <FormattedMessage {...messages.title} />
          </PHeading>

          {formattedDate ? (
            <PText>
              <FormattedMessage
                {...messages.subTitle}
                values={{
                  date: formattedDate,
                }}
              />
            </PText>
          ) : null}

          <InlineTariff
            plan={plans?.[1] || ({} as RawPlan)}
            marketplace={subscription.subscription.country}
            removeDisclaimer={true}
            newPriceUpdatePlan={plans?.[0]}
          />

          {formattedDate ? (
            <PTag icon="chat" color="notification-info-soft">
              <FormattedMessage
                {...messages.updateDateNotificationText}
                values={{
                  date: formattedDate,
                }}
              />
            </PTag>
          ) : null}

          <div className={style.buttonContainer}>
            <PButton onClick={() => navigate(generateRoute(Routes.Overview))}>
              <FormattedMessage {...messages.actionButtonLabel} />
            </PButton>
          </div>

          <AdditionalDisclaimer />
        </div>
      ) : null}
    </div>
  );

  return (
    <>
      {plans && plans?.length > 1 ? (
        <>
          <div className={style.desktopView}>
            <FrostedLayout background={background}>{content}</FrostedLayout>
          </div>

          <div className={style.mobileView}>{content}</div>
        </>
      ) : null}
    </>
  );
});
