import { FC } from 'react';

import style from './footer.module.scss';
import { useFooter } from './useFooter';

type FooterProps = {
  locale: string;
};

export const Footer: FC<FooterProps> = () => {
  const footerContent = useFooter();

  return <div className={style.root}>{footerContent}</div>;
};
