import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { messages } from './baseFee.messages';
import { planContext } from '../../../context/plan/PlanContext';
import style from './baseFee.module.scss';
import { PText } from '@porsche-design-system/components-react';
import { PlanVariant, Region } from 'components/context/plan/planContext.types';

/**
 * Non styled base fee of current {@link planContext}
 */
export const BaseFee: React.FunctionComponent = observer((_props) => {
  const context = useContext(planContext);
  const intl = useIntl();
  const isNarInclusivePremium =
    (context.region === Region.US || context.region === Region.CA) &&
    context.variant === PlanVariant.V2_PREMIUM_INCLUSIVE;

  const baseFee = context.priceUpdateBaseFee
    ? context.priceUpdateBaseFee
    : context.baseFee;

  const baseFeePrice = !isNarInclusivePremium ? (
    baseFee
  ) : (
    <div className={style.priceContainer}>
      {baseFee}
      <div className={style.star}>*</div>
    </div>
  );

  const isNarPremiumNonInclusive =
    (context.region === Region.US || context.region === Region.CA) &&
    context.variant === PlanVariant.V2_PREMIUM;

  return (
    <PText>
      {intl.formatMessage(messages.monthly, { baseFee: baseFeePrice })}
      {isNarPremiumNonInclusive ? '*' : null}
    </PText>
  );
});
