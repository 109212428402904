import { PText } from '@porsche-design-system/components-react';
import { breakpointS } from '@porsche-design-system/components-react/styles';
import { featureFlagsContext } from 'components/context/featureFlags/FeatureFlagsContext';
import { PremiumOnly } from 'components/context/plan/discriminators/tariff/PremiumOnly';
import {
  ChargePointRegistrationStatus,
  subscriptionContext,
} from 'components/context/subscription/SubscriptionContext';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import style from './flatTariff.module.scss';
import { NorthAmericaOnly } from '../../../../context/plan/discriminators/region/NorthAmericaOnly';
import { NarPremiumInclusiveOnly } from '../../../../context/plan/discriminators/tariff/NarPremiumInclusiveOnly';
import { messages } from '../../../../tariff/components/membership/membership.messages';

interface FlatTariffProps {}

/**
 * Flat tariff display, styled only for the tariff overview
 * @param props
 * @constructor
 */

export const FlatTariff: React.FunctionComponent<FlatTariffProps> = observer(
  () => {
    const context = useContext(subscriptionContext);
    const { featureFlags } = useContext(featureFlagsContext);

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isMobileWidth = width <= breakpointS;

    return (
      <NorthAmericaOnly>
        <NarPremiumInclusiveOnly>
          <div>
            <div>
              <PText>
                <FormattedMessage {...messages.inclusiveDc} />
              </PText>
            </div>
            <div className={style.alignRight}>
              <div>
                <PText weight={'bold'}>
                  <FormattedMessage
                    {...messages.inclusiveFlatrate}
                    values={{ amount: '30' }}
                  />
                </PText>
              </div>
              <div>
                <PText>
                  <FormattedMessage {...messages.inclusiveSubtitle} />*
                </PText>
              </div>
            </div>
          </div>
          <div>
            <div>
              <PText>
                <FormattedMessage {...messages.inclusiveGrace} />
              </PText>
            </div>
            <div>
              <PText weight={'bold'}>
                <FormattedMessage
                  {...messages.inclusiveGraceText}
                  values={{ amount: '10' }}
                />
              </PText>
            </div>
          </div>
          <div>
            <div>
              <PText>
                <FormattedMessage {...messages.inclusiveIdle} />
              </PText>
            </div>
            <div>
              <PText weight={'bold'}>
                <FormattedMessage {...messages.inclusiveIdleSubtitle} />
              </PText>
            </div>
          </div>
        </NarPremiumInclusiveOnly>
        {featureFlags.chargePoint?.enabled ? (
          <PremiumOnly>
            <div>
              <div>
                <PText>
                  <FormattedMessage {...messages.inclusiveChargePoint} />
                </PText>
              </div>
              {!context.subscription?.chargePointRegistration?.status ||
              ![
                ChargePointRegistrationStatus.ACTIVATION_PENDING,
                ChargePointRegistrationStatus.REGISTERED,
              ].includes(
                context.subscription?.chargePointRegistration.status,
              ) ? (
                <div>
                  <PText
                    weight={'bold'}
                    align={isMobileWidth ? undefined : 'right'}
                  >
                    <FormattedMessage {...messages.chargePointNotAvailable} />
                  </PText>
                  <PText
                    color={'contrast-medium'}
                    align={isMobileWidth ? undefined : 'right'}
                  >
                    <FormattedMessage {...messages.chargePointCTA} />
                  </PText>
                </div>
              ) : context.subscription?.chargePointRegistration.status ===
                ChargePointRegistrationStatus.ACTIVATION_PENDING ? (
                <div>
                  <PText weight={'bold'}>
                    <FormattedMessage
                      {...messages.chargePointActivationPending}
                    />
                  </PText>
                </div>
              ) : context.subscription?.chargePointRegistration.status ===
                ChargePointRegistrationStatus.REGISTERED ? (
                <div>
                  <PText
                    weight={'bold'}
                    align={isMobileWidth ? undefined : 'right'}
                  >
                    <FormattedMessage {...messages.chargePointRegistered} />
                  </PText>
                  <PText
                    color={'contrast-medium'}
                    align={isMobileWidth ? undefined : 'right'}
                  >
                    <FormattedMessage
                      {...messages.chargePointRegisteredSubContent}
                    />
                  </PText>
                </div>
              ) : null}
            </div>
          </PremiumOnly>
        ) : null}
      </NorthAmericaOnly>
    );
  },
);
