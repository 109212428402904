import {
  PButtonPure,
  PDivider,
  PIcon,
  PText,
} from '@porsche-design-system/components-react';
import { Map } from 'components/misc/map/Map';
import { ChargingSession } from 'pages/management/components/SubscriptionHistoryList/SubscriptionHistoryList';
import { FC, MouseEventHandler, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatDate } from 'utils/formatDate';

import { messages } from './subscriptionHistoryItem.messages';
import style from './subscriptionHistoryItem.module.scss';

type SubscriptionHistoryItemsProps = {
  isOpen?: boolean;
  toggleOpen: MouseEventHandler;
  item: ChargingSession;
};

const sumFees = (session: ChargingSession, feeTypes: string[]) => {
  return Object.entries(session.charges).reduce((sum, [feeType, charge]) => {
    if (!feeTypes.includes(feeType)) {
      return sum;
    }

    return sum + (charge.price?.grossAmount?.decimal || 0);
  }, 0);
};

export const SubscriptionHistoryItem: FC<SubscriptionHistoryItemsProps> = ({
  isOpen,
  toggleOpen,
  item,
}) => {
  const { locale } = useIntl();

  const currency = useMemo(() => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: item.totalPrice.grossAmount.currency,
    });
  }, [item.totalPrice.grossAmount.currency, locale]);

  const region = useMemo(() => {
    const country = (locale || 'xx-XX').split('-')[1];
    switch (country) {
      case 'US':
      case 'CA':
        return 'NAR';
      default:
        return 'default';
    }
  }, [locale]);
  const startDate = useMemo(() => new Date(item.session.startedAt), [item]);
  const endDate = useMemo(() => new Date(item.session.endedAt), [item]);
  const formattedDuration = useMemo(() => {
    const sessionTimeDuration = Math.abs(
      startDate.getTime() - endDate.getTime(),
    );
    const hoursDiff = sessionTimeDuration / (1000 * 60 * 60);
    const hours = Math.floor(hoursDiff);
    const minutes = Math.floor((hoursDiff - hours) * 60);

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
  }, [startDate, endDate]);

  const startEndTimeFormatted = useMemo(() => {
    const startTimeHour = startDate.getHours().toString().padStart(2, '0');
    const startTimeMinutes = startDate.getMinutes().toString().padStart(2, '0');
    const endTimeHour = endDate.getHours().toString().padStart(2, '0');
    const endTimeMinutes = endDate.getMinutes().toString().padStart(2, '0');

    return `${startTimeHour}:${startTimeMinutes} - ${endTimeHour}:${endTimeMinutes}`;
  }, [startDate, endDate]);

  const addressFormatted = useMemo(() => {
    const {
      street = '',
      houseNumber = '',
      city = '',
      postalCode = '',
      state = '',
    } = item.chargePoint.address;

    let parts = [`${street} ${houseNumber}`, `${postalCode} ${city}`];
    if (region === 'NAR') {
      parts = [
        `${street} ${houseNumber}`,
        `${city}`,
        `${state}`,
        `${postalCode}`,
      ];
    }

    return parts
      .map((entry) => entry.trim())
      .filter((entry) => !!entry)
      .join(', ');
  }, [item.chargePoint.address, region]);

  return (
    <div
      className={style.accordionWrapper}
      data-testid="subscriptionHistoryItem-main-wrapper"
    >
      <div className={style.contentWrapper}>
        <div
          className={
            isOpen
              ? style.previewContentWrapper
              : style.previewContentWrapperClosed
          }
        >
          <PIcon name="charging-station" className={style.topLeftIcon} />

          <div className={style.previewMainContent}>
            <div className={style.previewDateAndTimeWrapper}>
              <PText size="small">
                {formatDate(new Date(item.session.startedAt), false)}
              </PText>
              <PDivider
                className={style.verticalTextDivider}
                direction="vertical"
                color="contrast-high"
              />
              <PText className={style.startEndTime} size="small">
                {startEndTimeFormatted}
              </PText>

              <div className={style.desktopChargingTimeContainer}>
                <PDivider
                  className={style.verticalTextDivider}
                  direction="vertical"
                  color="contrast-high"
                />
                <PText
                  color="contrast-medium"
                  size="small"
                  className={style.accordionTopRightLabel}
                >
                  <FormattedMessage {...messages.charginTimeLabel} />
                </PText>
                <PText size="small">{formattedDuration}h</PText>
              </div>
            </div>

            <PText weight="bold" size="medium">
              {addressFormatted}
            </PText>
            <PText>{item.chargePoint.operator}</PText>

            <div className={style.mobilePriceAndDurationContainer}>
              <div className={style.mobileDurationContainer}>
                <PText
                  color="contrast-medium"
                  size="small"
                  className={style.accordionTopRightLabel}
                >
                  <FormattedMessage {...messages.charginTimeLabel} />
                </PText>
                <PText size="small">{formattedDuration}h</PText>
              </div>

              <div className={style.mobileCostContainer}>
                <PText
                  color="contrast-medium"
                  size="small"
                  className={style.accordionTopRightLabel}
                >
                  <FormattedMessage {...messages.charginCostLabel} />
                </PText>
                <PText
                  className={style.accordionTopRightSecondValue}
                  weight="bold"
                >
                  {currency.format(item.totalPrice.grossAmount.decimal)}
                </PText>
              </div>
            </div>
          </div>

          <div className={style.previewRightContent}>
            <div className={style.costContainer}>
              <PText
                className={style.accordionTopRightSecondValue}
                size="large"
                weight="bold"
              >
                {currency.format(item.totalPrice.grossAmount.decimal)}
              </PText>
              <PText
                color="contrast-medium"
                size="small"
                className={style.accordionTopRightLabel}
              >
                <FormattedMessage {...messages.charginCostLabel} />
              </PText>
            </div>

            <PButtonPure
              onClick={toggleOpen}
              icon={isOpen ? 'arrow-head-up' : 'arrow-head-down'}
              data-testid="subscriptionHistoryItem-open-button"
            />
          </div>
        </div>

        {isOpen ? (
          <div
            className={style.accordionInnerContentWrapper}
            data-testid="subscriptionHistoryItem-open-wrapper"
          >
            <Map
              address={`${item.chargePoint.address.street} ${item.chargePoint.address.houseNumber || ''}, ${item.chargePoint.address.postalCode || ''} ${item.chargePoint.address.city}`}
            />

            <div className={style.accordionInnerContentDetails}>
              <div className={style.iconWithTextContainer}>
                <PIcon
                  size="large"
                  className={style.iconInnerContent}
                  name="purchase"
                />

                <div className={style.labeledPrice}>
                  <PText size="large" weight="bold">
                    {currency.format(
                      sumFees(item, ['ENERGY', 'OVERAGE', 'SESSION']),
                    )}
                  </PText>
                  <PText color="contrast-medium">
                    <FormattedMessage {...messages.charginCostFullLabel} />
                  </PText>
                </div>
              </div>

              <div className={style.iconWithTextContainer}>
                <PIcon
                  size="large"
                  className={style.iconInnerContent}
                  name="garage"
                />

                <div className={style.labeledPrice}>
                  <PText size="large" weight="bold">
                    {currency.format(
                      item.charges['BLOCKING']
                        ? item.charges['BLOCKING'].price.grossAmount.decimal
                        : item.charges['IDLE']?.price.grossAmount.decimal || 0,
                    )}
                  </PText>
                  <PText color="contrast-medium">
                    <FormattedMessage {...messages.charginBlockingFeeLabel} />
                  </PText>
                </div>
              </div>

              <div className={style.innerLabeledText}>
                <PText
                  color="contrast-medium"
                  className={style.innerContentRightLabel}
                >
                  EVSE-ID:
                </PText>
                <PText>{item.chargePoint.evseId}</PText>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <PDivider className={style.bottomDivider} />
    </div>
  );
};
