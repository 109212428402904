import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  autoRenewTitle: {
    defaultMessage: 'Subscription renewal',
    id: 'flyout.overview.duration.autoRenew.title',
  },
  endDateTitle: {
    defaultMessage: 'Contract duration',
    id: 'flyout.overview.duration.endDate.title',
  },
  endDateText: {
    defaultMessage: 'Contract ends on {date}',
    id: 'flyout.overview.duration.endDate.text',
  },
});
